/*Describe group of button style*/
.toggle_btn_group {
    display: inline-grid;
    grid-template-rows: auto auto;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.button_title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #57aa60;
}

.button_container {
    display: flex;
    justify-content: center;
}

/*Describe single buttons style*/

.spb_center, .spb_left, .spb_right {
    height: 50px;
    min-width: 61px;
    padding: 3px;
    border: 0;
    cursor: pointer;
}

.toggle_btn_group {
    margin: 8px;
}

.spb_center, .spb_left {
    border-right: 2px solid white;
}


.spb_left {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
.spb_right {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.pressed {
    background: #57aa60a3;
}