.line_container {
    display: grid;

    grid-template-columns:
            [col-start] 50px [c1]
            minmax(150px, 1fr) [c2]
            minmax(150px, 1fr) [c3]
            minmax(150px,1fr) [c4]
            minmax(120px, 0.5fr) [c5]
            minmax(100px, 0.5fr) [c6]
            1fr [col-end];
    grid-template-rows: [row1-start] 24px [row1-end] 12px [mid-second] 12px [row2-end] 24px [row-end] auto [note-end];
    grid-template-areas:
          ".   company  sn    days      l1  date1 buttons"
          "num company  sn    label     l2  date2 buttons"
          "num city     type  label     l2  date2 buttons"
          ".   city     type  verify_date l3  date3 buttons "
          "vuoto note    note  note      note note  note";

    padding: 2px;
    margin: 0 5px;
    justify-items: start;
    align-items: center;
    border: 1px solid #62626875;
}

.line_container:hover {
    box-shadow: 0 0 50px 5px #73dbfb inset
}

.line_container.header:first-child {
    justify-items: center;
    align-content: center;
    grid-template-rows: auto;
    font-weight: bold;
    background: #57aa60;
    border: 0 solid;
    color: whitesmoke;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.line_container.header:first-child:hover {
    box-shadow: none;
}

.line_container:nth-child(even) {
    background-color: #e3f6f9;
}

.line_container:nth-child(odd) {
    background-color: #f5feff;
}

/*!*Lateral buttons*!*/
/*.my_button {*/
/*    background: #fdfdfd;*/
/*    padding: 0 3px;*/
/*    margin: 1px;*/
/*    border-radius: 25px;*/
/*    text-align: center;*/
/*    cursor: pointer;*/
/*    display: inline-block;*/
/*}*/

.my_button {
    display: flex;
    align-items: center; /* Centra verticalmente */
    gap: 8px; /* Spazio tra l'icona e il testo */
    cursor: pointer;
    padding: 0 3px;
    margin: 1px;
    border-radius: 25px;
    background: #fdfdfd;
}

.my_button img {
    height: 2em; /* Imposta l'altezza dell'immagine pari all'altezza del testo */
    width: auto; /* Mantiene le proporzioni */
}

.button_text {
    font-size: 1em; /* Assicura che il testo e l'icona abbiano la stessa altezza */
}


/*.my_button img {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*}*/

.my_button:hover {
    background: #57aa60;
    border-color: #000;
    color: white;
}

@media (max-width: 1000px) {
    span.button_text {
        display: none;
    }
    .line_container.header:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        padding-top: 1px;
    }

    .d2_status {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        max-width: 5ch;
    }

    .d2_status.expired {
        max-width: 4ch;
    }

    .date_year {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        max-width: 2ch;
        direction: rtl;
    }

    span.ver_t {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ".";
        max-width: 6ch; /* Mostra solo i primi 6 caratteri */
    }

    .line_container {
        padding: 0;
        margin: 0;
        grid-template-columns:
                [col-start] 25px
                [c1] minmax(150px, 2fr)
                [c2] minmax(150px, 1fr)
                [c3] minmax(110px, 0.5fr)
                [c4] minmax(40px, 0.5fr)
                [c5] minmax(65px, 0.5fr)
                [c6] minmax(60px, 0.5fr) [col-end];
        grid-template-rows: [row1-start] 13px [row1-end] 13px [mid-second] 13px [row2-end] 15px [row-end];
    }

    .my_button {
        padding: 0 1px;
        margin: 1px;
        border-radius: 5px;
        border: 1px solid #62626875;
    }
}
