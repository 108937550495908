
.insert_popup {
    z-index: 5;
    position: fixed;
    top: 5%;
    width: 90%;
    height: 90%;
    border: 1px solid black;
    background: white;
    margin: auto;
    left: 5%;
}