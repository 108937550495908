.matr_insert, .fill_fields, .subtitle_fill_fields {
    display: flex;
    margin: 15px;
}

.matr_field_title {
    padding: 5px 2px;
    margin: auto;
}

.matr_field {
    flex-grow: 1;
}

.fill_fields {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
}

.field_line {
    padding: 5px 0;
    margin: 5px;
    width: 20%;
}

.long_field {
    width: 100%
}

.short_field {
    width: 200px;
}

.field_grid {
    margin: 10px 5px;
}