.title_div {
    background: #57aa60;
}

.title_bar_div {
    height: 10%;
    display: flex;
}

.title_bar_buttons {
    display: flex;
}

.title_icon {
    height: 70px;
    padding: 10px 40px;
}

.title_button {
    height: 40px;
    padding: 3px 10px ;
    margin: 0 10px;
    border: 0 solid #ffffff;
    background: rgba(86, 169, 95, 0);
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: white;
    cursor: pointer;
    transition: border-width 0.1s linear;
}

.title_button:hover, .title_button.selected {
    border-bottom: 5px solid #ffffff;
    color: #ddefff;
}

.main_title {
    font-size: 32px;
    color: #ddefff;
    align-self: center;
}