.main_fill_section {
    display: block;
    max-width: 1500px;
    margin: 20px auto;
    border-radius: 10px;
    padding:20px;
    /*background-color: #e3e9fa;*/
}

.main_fill_section .title {
    margin: 15px 0;
    padding: 0;
}

.main_fill_section .description {
    margin: 15px 0 25px;
    padding: 5px 0;
    color: #404042;
    font-style: italic;
}


div.select_date_line, div.drop_down, div.insert_text_line {
    display: flex;
    flex-flow: row wrap;
    padding: 3px;
    margin-bottom: 7px;
    gap: 10px;
    align-items: center;
    justify-content: safe flex-start;
    border-radius: 3px;
    border: #eaeaea 1px solid;
}

div.insert_text_line .textarea {
    flex-grow: 1;
    border-radius: 10px;
    padding: 10px 6px;
}

div.select_date_line .button_container button {
    flex-grow: 1;
    width: 100px;
    height: 80%;
    min-height: 40px;
}

div.select_date_line .button_container button.selected {
    background-color: #57aa60;
    color: white;
    transform: scale(0.98);
    box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.24);
}

div.select_date_line .button_container button:hover {
    background-color: #57aa60;
}

div.select_date_line input, div.drop_down select {
    height: 80%;
    border-radius: 10px;
    padding: 10px 6px;
    min-width: 61px;
}

div.select_date_line input{
    background-color: white;
    border: #606060 solid 1px;
}

div.select_date_line input:focus{
    outline: #57aa60 solid 2px;
}

span.label {
    margin-top: auto;
    margin-bottom: auto;
}

.main_fill_section .confirmation_section {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
}

.confirmation_section button {
    padding: 10px;
    margin: 3px;
    flex-grow: 2;
    max-width: 200px;
    height: 40px;
    cursor: pointer;
}
.confirmation_section button:hover {
    background-color: #57aa60;
    color: white;
    box-shadow: 3px 2px 15px 1px rgba(0, 0, 0, 0.24);
}

.confirmation_section button.reset:hover {
    background-color: #860000;
}

div.select_date_line .button_container button.spb_left:hover, div.select_date_line .button_container button.spb_left.selected {
    background-color: #606060;
    color: white;
}

div.info-section {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    justify-content: space-evenly;
}