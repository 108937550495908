/* Add this to your CSS file */
.technical-data-table table {
    width: 100%;
    border-collapse: collapse;
}

.technical-data-table {
    margin-bottom: 10px;
}

.technical-data-table th, .technical-data-table td {
    border: 1px solid #000000;
    padding: 8px;
    height: 1em;
}

.technical-data-table th, .technical-data-row .tdt_label{
    background-color: #f2f2f2;
    text-align: left;
}

.technical-data-table th, .technical-data-table td {
    border: 1px solid #000000;
    padding: 8px;
    height: 1em;
}

.technical-data-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.technical-data-row .tdt_label{
    font-weight: bold;
}

.technical-data-row .tdt_label,
.technical-data-row .tdt_value {
    flex: 1 1;
    padding: 8px;
    border: 1px solid #000000;
    align-content: center;
}


