.selection_table {
    margin: 20px;
}

.selection_line {
    display: grid;
    grid-template-columns: 40px minmax(150px, 1fr) minmax(130px, 1fr) 70px 70px minmax(100px, 3fr);
    grid-gap: 1px; /* Adjust the gap size as desired */
    background-color: rgba(211, 211, 211, 0.38);
    margin: 0 auto;
    align-items: center;
}

.selection_line:nth-child(even) {
    background-color: white;
}

.selection_line.selection_line_title {
    font-size: large;
    background-color: #57aa60;
    color: white;
    font-weight: bold;
    text-align: center;
    border-left: 0;
    border-right: 0;
    padding: 0;
}


.selection_line * {
    /*border-bottom: #d2d2d2 solid 1px;*/
    /*border-left: #d2d2d2 solid 1px;*/
    /*border-right: #d2d2d2 solid 1px;*/
    /*background-color: white;*/
    padding: 10px 10px 10px 10px;
}

.selection_line .editable {
    background: rgba(148, 148, 148, 0.29);
    padding: 6px 4px;
    margin: 3px;
}

.note_error {
    background: rgba(255, 0, 0, 0.5); /* Red background to indicate error */
}

.closing {
    background: rgba(0, 255, 0, 0.5); /* Green background to indicate saving */
    animation: pulse 1s infinite; /* Add a pulsing animation */
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.save_note {
    height: 1.3em;
    padding: 0 2px;
    margin: 0 2px;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 5px; /* Rounded gray border */
}

.save_note:hover {
    background: rgba(0, 255, 0, 0.5);
    border: 1px solid dimgray;
}

.select_row_column {
}

.expiring_sn {
    background: rgba(224, 189, 15, 0.61);
}
.expired_sn {
    background: rgba(247, 127, 127, 0.7);
}

.sn_selected {
    background: rgba(87, 170, 96, 0.55);
 }

.p_column,.v_column  {
    text-wrap: none;
    white-space: nowrap;
    /*font-size: small;*/
    font-weight: lighter;
    text-align: right;
}

.p_column::after,.v_column::after {
    content: attr(data-unit);
    position: relative;
    left: 4px;
    font-size: 0.8em;
}



.sn_column, .type_column, .info_column, .p_column,.v_column {

}
