/* src/CSS/InfoBox.css */
div.info-box {
    flex: 1 1 auto;
    min-width: 400px;
    max-width: 550px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: #f7e0e0;
}


div.info-box.client {
    background-color: #e0f7e0;
}

div.info-box.equipment {
    background-color: #e0f7ff;
}


div.info-box .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.info-box .header h2 {
    color: #606060; /* Grigio scuro */
}

div.info-box .details p.info-line {
    color: #606060; /* Grigio chiaro */
    display: flex;
    align-items: center;
}

div.info-box .details span.info-label {
    min-width: 80px;

}

div.info-box .details span.info {
    display: inline-block;
    color: #000000; /* Nero */
    font-weight: bold;
    font-style: normal;

}