.page_button {
    margin: 2px;
    padding: 5px;
    border: 0;
    cursor: pointer;
}

.p_selected {
    background: #57aa60a3;
}

.page_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10px auto;
    max-width: 1500px;
}

.page_section > * {
    margin: 2px 40px;
}