.detail_container {
    padding: 2px;
    border: 2px solid #A9A9A9FF;
    border-top: 0;
    margin: 0 5px 12px;
}

.detail_container div:first-child {
    border-bottom: 1px dashed #A9A9A9FF;
}

.subtitle {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 19px;
    margin: 5px;
    font-weight: bold;
}

.detail_info {
    text-align: center;
    display: block;
    margin: 5px;
}

.report_list {
    display: block;
    margin: 5px 2px;
}

.report_line.rl_header {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: #e9e9ed;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    margin-top: 9px;
    color: #386f3d;
}

.report_line {
    height: 40px;
    display: grid;
    grid-template-columns: 50px 1fr 100px 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
}

.report_line:nth-child(2n+3) {
    background-color: #f5feff;
}

.report_line:nth-child(even) {
    background-color: #e3f6f9;
}

.ver_type i.material-icons {
    margin-right: 4px;
}

.doc_icon {
    height: 37px;
    padding: 1px 2px;
    cursor: pointer;
    align-self: start;
}

.filename {
    font-style: italic;
}

.report_buttons {
    display: flex;
    justify-content: space-around;
}

span.report_line.deletion_in_progress{
    background-color: #ffcccc;
}

span.report_line.deletion_in_progress * {
    animation: blurAnimation 2s infinite;
}

span.report_line.deletion_failed {
    background-color: #ff1313;
}

@keyframes blurAnimation {
    0% {
        filter: blur(0px);
    }
    50% {
        filter: blur(1px);
    }
    100% {
        filter: blur(0px);
    }
}